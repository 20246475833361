import logo from './logo.svg';
import {useState} from 'react';
import './App.css';

function App() {
  const [text, setText] = useState("hello world")
  fetch('https://test-node.dp-03.axsms.us/', {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }
  })
  .then(response => response.text())
  .then(data => {
    console.log(data)
    setText(data);
  });

  return (
    <div className="App">
      {text}
    </div>
  );
}

export default App;
